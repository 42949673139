
import bg1 from "../../images/t1.jpg";
import bg2 from "../../images/t2.jpg";
import bg3 from "../../images/t3.jpg";
import bg4 from "../../images/t4.jpg";


function imgdiv() {
  return (
    <div>
    <section class="hero-section">
              <div class="card-grid">
                  <a class="card_cat" href="#">
                  <div class="card__background" style={{backgroundImage: `url(${bg1})`}}></div>
                  <div class="card__content"> </div>
                  </a>
                  <a class="card_cat" href="#">
                  <div class="card__background" style={{backgroundImage: `url(${bg2})`}}></div>
                  <div class="card__content"> </div>
                  </a>
                  <a class="card_cat" href="#">
                  <div class="card__background" style={{backgroundImage: `url(${bg3})`}}></div>
                  <div class="card__content"></div>
                  </a>
                  <a class="card_cat" href="#">
                  <div class="card__background" style={{backgroundImage: `url(${bg4})`}}></div>
                  <div class="card__content"> </div>
                  </a>
              </div>
    </section>
    </div>
  );
}
export default imgdiv;