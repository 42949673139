import bgw from "../../images/choose-list-background.jpg";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-out-cubic",
  delay: 50
});


function Whychose() {
  return (
    <div>
      <div class="container mb-5 mt-5 ar" id="whychose" >
        <div class="row">
          <div class="col-md-6" >
            <div data-aos="fade-right"> 
              <img class="why-chose-us" src={bgw} />
            </div>
          </div>
          <div class="col-md-6 p-5" >
            <div data-aos="fade-left"> 
            <h2>لماذا أخترتنا؟</h2> <br />
            <p>
            مع تاريخ خدمة يزيد عن ست سنوات ، يسعدنا خدمتك.
               <br /> <br />
               نحن نهتم بوقتك الثمين
              <br /><br />
              نحن نهتم بأموالك
              <br /><br />
              نحن نهتم برضاك
              <br /><br />
              نبذل قصارى جهدنا وخبرتنا لتحسين خدمتك
            </p>
            </div>
          </div>
        </div>
      </div>
<br /><br />
    </div>
  );
}
export default Whychose;