
import ty1 from "../../images/ty1.jpg";
import ty2 from "../../images/ty2.jpg";
import { zoomInLeft, zoomInRight } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


const styles = {
    zoomInLeft: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInLeft, 'zoomInLeft')
    },
    zoomInRight: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInRight, 'zoomInRight')
    }
  }
  

function Divs() {
  return (
    <StyleRoot>
    <div>
        <div className="container-fluid" >
            <div className="row mt-5">
                <div className="col-md-6" style={styles.zoomInLeft}>
                    <figure class="snip0056">
                        <figcaption>
                        <h2><span>Typing </span>Services</h2>
                        <p>Typingvisa offers an array of specialized, accurate online and offline typing services at very competitive rates. </p>
                        {/* <div class="icons"><a href="#"><i class="ion-ios-home"></i></a><a href="#"><i class="ion-ios-email"></i></a><a href="#"><i class="ion-ios-telephone"></i></a></div> */}
                        </figcaption>
                        <img  src={ty1} alt="sample8" />
                        {/* <div class="position">Web Designer</div> */}
                    </figure>
                </div>
                <div className="col-md-6" style={styles.zoomInRight}>
                    <figure class="snip0056 yellow">
                    <figcaption>
                        <h2><span>Business Setup & PRO </span>Services</h2>
                        <p>We are the leading Business setup service providers. Setting up a company in Sharjah is more comfortable only if you have access to government legalities. </p>
                        {/* <div class="icons"><a href="#"><i class="ion-ios-home"></i></a><a href="#"><i class="ion-ios-email"></i></a><a href="#"><i class="ion-ios-telephone"></i></a></div> */}
                    </figcaption>
                    <img  src={ty2} alt="sample8" />
                    {/* <div class="position"><Link href="" >Watsapp </Link></div> */}
                    </figure>
                </div>
            </div>
        </div>
    </div>
    </StyleRoot>
  );
}
export default Divs;