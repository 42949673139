import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import { lazy, Suspense } from "react";
import Home from './en/components/Home';
import Contact from './en/components/Contact';
import About from './en/components/About';
import Services from './en/components/Services';
import Footer from './en/components/Footer';
import ArabicHome from './ar/components/Home';
import ArabicServices from './ar/components/Services';
import ArabicAbout from './ar/components/About';
import ArabicContact from './ar/components/Contact';
import './css/custom.css';
import './css/new.css';


// import Header from './components/Header/index';
class App extends React.Component {
  state = {
    loading: true
  };
  componentDidMount() {
    this.fakeRequest().then(() => {
      const el = document.querySelector(".load_page");
      if (el) {
        el.remove();  // removing the spinner element
        this.setState({ loading: false }); // showing the app
      }
    });
  }

  fakeRequest = () => {
    return new Promise(resolve => setTimeout(() => resolve(), 2500));
  };

  render() {
    if (this.state.loading) {
      return null; //app is not ready (fake request is in process)
    }
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path='/ar/' component={ArabicHome}>
              <ArabicHome />
            </Route>
            <Route exact path='/ar/services' component={ArabicServices}>
              <ArabicServices />
            </Route>
            <Route exact path='/ar/About' component={ArabicAbout}>
              <ArabicAbout />
            </Route>
            <Route exact path='/ar/Contact' component={ArabicContact}>
              <ArabicContact />
            </Route>
            <Route exact path='/' component={Home}>
              <Home />
            </Route>
            <Route exact path='/contact' component={Contact}>
              <Contact />
            </Route>
            <Route exact path='/About' component={About}>
              <About />
            </Route>
            <Route exact path='/services' component={Services}>
              <Services />
            </Route>
          </Switch>
        </div>
    </BrowserRouter>
    );
  }
}

export default App;