import Ferrari from "./../img/sh11.jpg";
import Lamborghini from "./../img/sh2.jpg";
import Mustang from "./../img/sh3.jpg";
import Porsche from "./../img/sh4.jpg";

const sliderData = [
  {
    id: 1,
    image: Ferrari,
    carName: "Document Typing Services",
    content:
      "You Can Contact Us for All Types Of Document Typing Services.",
  },
  {
    id: 2,
    image: Lamborghini,
    carName: "Travel and Tourism",
    content:
      "We Provide Travelling Services to Different Countries, Book your trip with us.",
  },
  {
    id: 3,
    image: Mustang,
    carName: "Visa Services",
    content:
      "We Provide All Types of Visa Services. That might suit your needs.",
  },
  {
    id: 4,
    image: Porsche,
    carName: "Tasheel Services",
    content:
      "Contact Us For All Types Of Tasheel Services.",
  },
];

export default sliderData;
