import React, { useRef, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css';
import '../css/arabic.css'
import Divs from "./Common/Divs";
import Imgdiv from "./Common/Imgdiv";
import Services from "./Common/Services";
import Whychose from "./Common/Whychose";
import Slider from "./Slider/Slider";
import Nav from "./Navbar";
import Footer from "./Footer";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init({
  offset: 200,
  duration: 600,
  easing: "ease-in-sine",
  delay: 100
});

function ArabicHome() {
  return (
    <div className="ar">
      <Nav />
      <Slider />
      <Divs />
      <Imgdiv />
      <Services />
      <Whychose />
      <Footer />
   </div>
  );
}



export default ArabicHome;