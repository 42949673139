import React from 'react';
import './Contact_address.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHeadset, faMapMarkerAlt, faGlobe } from "@fortawesome/free-solid-svg-icons";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-out-cubic",
  delay: 50
});

 function Contact_address() {
  return (
    <div>
      <div class="container-fluid call_icons">
          <div class="row text-center">
            <div class="col-sm-3 col-xs-6 first-box" >
              <div data-aos="zoom-in-right">
                <h1><span class="glyphicon glyphicon-earphone"></span></h1>
                <h3><FontAwesomeIcon icon={faHeadset} size="4x" /></h3>
                <h4>+971-563800082</h4><br />
                </div>
            </div>
            <div class="col-sm-3 col-xs-6 second-box" >
              <div data-aos="zoom-in-down">
                <h1><span class="glyphicon glyphicon-home"></span></h1>
              <h3><FontAwesomeIcon icon={faEnvelope} size="4x" /></h3>
                <h4>info@typingvisa.com</h4><br />
                </div>
            </div>
            <div class="col-sm-3 col-xs-6 third-box" >
              <div data-aos="zoom-in-down">
                <h1><span class="glyphicon glyphicon-send"></span></h1>
                <h3><FontAwesomeIcon icon={faMapMarkerAlt} size="4x" /></h3>
                <h4>Opposite AlSajaa Police Station, AlSajaa Industiral Area, Sharjah</h4><br />
                </div>
            </div>
            <div class="col-sm-3 col-xs-6 fourth-box" >
              <div data-aos="zoom-in-left">
              <h1><span class="glyphicon glyphicon-leaf"></span></h1>
                 <h3><FontAwesomeIcon icon={faGlobe} size="4x" /></h3>
                <h4>www.typingvisa.com</h4><br />
                </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Contact_address
