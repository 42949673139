import em1 from "../../images/em1.jpg";
import em2 from "../../images/em2.jpg";
import em3 from "../../images/em3.jpg";
import em4 from "../../images/em4.jpg";
import em5 from "../../images/em5.jpg";
import em6 from "../../images/em6.jpg";
import em7 from "../../images/em7.jpg";
import em8 from "../../images/em8.jpg";
import em9 from "../../images/em9.jpg";
import em10 from "../../images/em10.jpg";
import em11 from "../../images/em11.jpg";
import em12 from "../../images/em12.jpg";

import Aos from "aos";
import "aos/dist/aos.css";

import { zoomInLeft, zoomInRight, zoomInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


const styles = {
    zoomInLeft: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInLeft, 'zoomInLeft')
    },
    zoomInRight: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInRight, 'zoomInRight')
    },
    zoomInUp: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInUp, 'zoomInUp')
    }
  }

Aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-out-cubic",
  delay: 50
});


function Services() {
  return (
    <StyleRoot>
    <div>
      <div class="container-fluid wedo" id="wedo" >
        <div class="container p-5" >
          <div class="row">
            <h2  style={{fontWeight: "600"}}>Our Services</h2>
            <div class="col-md-4 mt-2 p-3" >
              <div style={styles.zoomInLeft}>
              <img src={em1} alt="" /> <br /><br />
              <h4 >Emirates ID Typing</h4>
              <p>It is a legal necessity for all UAE citizens and residents to apply for one and carry it with them at all times. </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div style={styles.zoomInUp}>
              <img src={em2} alt="" /> <br /><br />
              <h4>Tasheel Services</h4>
              <p>Tasheel is an online government system simplifying labor-related processes for both the employer and the employee. </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div style={styles.zoomInLeft}>
              <img src={em3} alt="" /> <br /><br />
              <h4>Visa Stamping</h4>
              <p>All individuals who intend to be resident in UAE need a visa stamped on passport changing immigration status to Resident. </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em4} alt=""  /> <br /><br />
              <h4>Medical Typing</h4>
              <p>All individuals who intend to be resident in UAE need Medical Typing to process visa stamped on passport changing immigration status to Resident. </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-up">
              <img src={em5} alt="" /> <br /><br />
              <h4>Car & Health Insurance</h4>
              <p>It provides financial security against physical damage or bodily injury from accidents and against liability.  </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em6} alt="" /> <br /><br />
              <h4>Legal Services</h4>
              <p>Legal services unite consumers with lawyers of various law firms. We bring together; clients and attorneys in UAE. </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em7} alt=""  /> <br /><br />
              <h4>Immigration Services</h4>
              <p>We make sure to proffer the best and accurate legal advice to our customers to dwell permanently in any country.</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-up">
              <img src={em8} alt="" /> <br /><br />
              <h4>SEDD Services</h4>
              <p>We provide Licensing Services, Commercial Permits, and Inspection Services and Business Control services</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em9} alt="" /> <br /><br />
              <h4>MOI Services</h4>
              <p>We Provide All Types of United Arab Emirates Ministry Of Interior Services.</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
                <img src={em10} alt=""  /> <br /><br />
                <h4>Visit Visa Services</h4>
                <p>Are you applying for a visit visa? We provide Pr, Work, Tourist, Business, Student & Family Visa.</p>
              </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-up">
              <img src={em11} alt="" /> <br /><br />
              <h4>Ticketing Services</h4>
              <p>We provide Licensing Services, Commercial Permits, and Inspection Services and Business Control services</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em12} alt="" /> <br /><br />
              <h4>Sharjah Police Services</h4>
              <p>We provide Sharjah Police services like Report First Incident, Report Criminal Case, etc</p>
            </div>
            </div>
          </div>
        </div>
      </div>
         <br />
    </div>
    </StyleRoot>
  );
}
export default Services;