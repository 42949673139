import React, { useRef, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import logo from "../images/logo.png";

import { NavLink, NavDropdown, Link } from 'react-router-dom';
function Nav() {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  
  return (
    <div>
     <div className={click ? "main-container" : ""}  onClick={()=>Close()} />
      <nav className="navbar" onClick={e => e.stopPropagation()}>
        <div className="nav-container">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {/* <li className="nav-item">
              <NavLink
                exact
                to="/ar/contact"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                <div className="navigation">
                <span >Language</span>

                <div className="navigation-content">
                    <Link to="../" className="link">English</Link>
                    <Link to="../ar/" className="link">عربى</Link>

              </div>
              </div>
              </NavLink>
            </li> */}

            
            <li className="nav-item">
              <NavLink
                exact
                to="../"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
               English
              </NavLink>
            </li>

            
            <li className="nav-item">
              <NavLink
                exact
                to="/ar/contact"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                اتصل بنا
              </NavLink>
            </li>


            <li className="nav-item">
              <NavLink
                exact
                to="/ar/about"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                نبذة
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/ar/services"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                خدمات
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/ar/"
                activeClassName="active"
                className="nav-links"
               onClick={click ? handleClick : null}
              >
                مسكن
              </NavLink>
            </li>
            
          </ul>
          <NavLink exact to="/" className="right-nav-logo ml-auto">
          <img
          alt=""
          src={logo}
          className="d-inline-block align-top"/>
          </NavLink>

          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
    </ div>
  );
}
export default Nav;