import React, { useRef, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css';
import Divs from "./Common/Divs";
import Imgdiv from "./Common/Imgdiv";
import Services from "./Common/Services";
import Whychose from "./Common/Whychose";
import Slider from "./Slider/Slider";
import About_2side from "./About/About_2side";
import Nav from "./Navbar";
import Footer from './Footer';
import { Helmet } from "react-helmet-async";
function About() {
  return (
    <div>
       <Helmet>
          <title>About - typingvisa | Document Typing Services in Sharjah</title>
          <meta name="description" content="Contact Typing Visa – Visatyping is one of the leading typing center in Sharjah. At Typingvisa, we offers all the typing services that are required for the various departments."/>
          <link rel="canonical" href="/About" />
          <meta name="keywords"content="document typing services, document typing centres, document photocopying services in Sharjah, typing centers near me "/>
        </Helmet>
<Nav />
<Slider />
<About_2side />
<Divs />
<Imgdiv />
<Services />
<Whychose />
<Footer />
</div>


  );
}

export default About;