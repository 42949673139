import React ,{ useState }  from 'react';
import axios from 'axios';
import './Contact.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHeadset, faAddressBook } from "@fortawesome/free-solid-svg-icons";
import ty3 from "../../images/ty3.jpg";
import ReCAPTCHA from 'react-google-recaptcha';
import Aos from "aos";
import "aos/dist/aos.css";

// const API = "http://taskmanager.alnokhatha.tech/email/";
// const API = "http://localhost/website/email/";
const API = "http://taskmanager.alnokhatha.tech/email/";

Aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-out-cubic",
  delay: 50
});

// const [disable, setDisable] = React.useState(true);

function onChange(value) {
  console.log('Captcha value:', value);
  axios({
    method: "POST",
    url: API+"captcha.php",
    data:  {'val' : value}
  }).then((response)=>{
    console.log(response);
    if (response.data.status === 'success') {
      
      // alert(response.data.message);
      // $('.mail-button').text('hhhh');
      // setDisable(false);
    } else if (response.data.status === 'fail') {
      // alert(response.data.message);
    }
  })
}



class ContactForm  extends React.Component {

  

    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          message: '',
        
        }
      }
      handleSubmit(e){
        e.preventDefault();
        // alert('55');
        axios({
          method: "POST",
          url:"http://taskmanager.alnokhatha.tech/email/sendmail.php",
          data:  this.state
        }).then((response)=>{
          console.log(response);
          if (response.data.status === 'success') {
            alert("Thank you, Your Email Has Been Sent Successfully.");
            this.resetForm()
          } else if (response.data.status === 'fail') {
            alert("Email Sending Failed")
          }
        })
      }
      resetForm(){
        this.setState({name: '', email: '', message: ''})
      }

render(){
    return(
        <>
    <section class="section-bg" style={{backgroundImage: `url(${ty3})`}}  data-scroll-index="7">
          <div class="overlay pt-100 pb-100 ">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-in-right">
                        <div class="contact-info">

                            <h2 class="contact-title">Have You Any Queries?</h2>
                            <p>Contact us for any types of queries. We are here to help you.</p>
                            <ul class="contact-info">
                                <li>
                                  <div class="info-right">
                                  <h4>Feel free to mail us for any types of your queries. <br /> <br /> We are here to help you. </h4>
                                  </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-in-left">
                            <div class="contact-form">
                                <div class="row">  
                                  <form  onSubmit={this.handleSubmit.bind(this)} method="POST">
                                      <div class="col-md-12">
                                          <div class="form-group">
                                            <input type="text" name="name" class="form-control" id="first-name" placeholder="Enter Your Name *" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                                          </div>
                                      </div>
                                      <div class="col-md-12">
                                          <div class="form-group">
                                            <input type="email" name="email" class="form-control" id="email" placeholder="Enter Your Email *" required="required" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                          </div>
                                      </div>
                                      <div class="col-md-12">
                                          <div class="form-group">
                                              <textarea rows="4" name="message" class="form-control" id="description" placeholder="Enter Your Message *" value={this.state.message} onChange={this.onMessageChange.bind(this)}/>
                                          </div>
                                      </div>
                                      <div class="col-md-12">
                                          {/* <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange}/> */}
                                          <ReCAPTCHA sitekey="6Leh340eAAAAAKVfwmplQPsbirxA5x16zC2y4FrW" onChange={onChange} />
                                      </div>
                                        <div class="col-md-12">
                                            <button type="submit" class="btn btn-lg mt-4 mail-button" style={{backgroundColor: '#b8860b', color:'white'}} >
                                            <FontAwesomeIcon icon={faEnvelope} size="2x" pull="left" /> <span>Mail Us</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                    </div>
               </div>
           </div>
              </div>
        </section>
        </>
    );
}

onNameChange(event) {
    this.setState({name: event.target.value})
}

onEmailChange(event) {
    this.setState({email: event.target.value})
}

onMessageChange(event) {
    this.setState({message: event.target.value})
}

}

export default ContactForm;


