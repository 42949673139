
import { Link } from 'react-router-dom';

function Footer() {

  return (
    <div>
       <footer class="pt-5 pb-4 mt-5" id="contact" >
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 mt-2 mb-4">
        <h5 class="mb-4 font-weight-bold">Connect</h5>
        <ul class="f-instagram">
          <li>
            <div class="row">
              <div class="col-1"><i class="fa fa-instagram"></i></div>
              <div class="col-10">
                <h6 class="font-weight-bold mb-0">@typingvisa</h6>
               
              </div>
            </div>
          </li>
    
 
          <li>
            <div class="row">
              <div class="col-1"><i class="fa fa-twitter"></i></div>
              <div class="col-10">
                <h6 class="font-weight-bold mb-0">@typingvisa</h6>
               
              </div>
            </div>
          </li>

          <li>
            <div class="row">
              <div class="col-1"><i class="fa fa-phone"></i></div>
              <div class="col-10">
                <h6 class="font-weight-bold mb-0">+971-563800082</h6>
               
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-1"><i class="fa fa-envelope-open"></i></div>
              <div class="col-10">
                <h6 class="font-weight-bold mb-0">info@typingvisa.com</h6>
               
              </div>
            </div>
          </li>
    
        </ul>
      </div>
    

      <div class="col-lg-3 col-md-6 col-sm-6 mt-2 mb-4">
        <h5 class="mb-4 font-weight-bold">We Provide </h5>
        <ul class="recent-post">
          <li>
           <span>Emirates ID Typing</span>
          </li>
          <li>
            <span>Tasheel Services </span>
          </li>
          <li>
            <span>Visa Stamping</span>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 mt-2 mb-4">
        <br /> 
        
        <br />
        <ul class="recent-post">
          <li>
           <span>Medical Typing</span>
          </li>
          <li>
            <span>Car & Health Insurance</span>
          </li>
          <li>
            <span>Legal Services</span>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 mt-2 mb-4">
        <h5 class="mb-4 font-weight-bold">CONNECT WITH US</h5>
        <ul class="social-pet mt-4">
          <li><a href="https://www.facebook.com/typingvisa/" title="facebook"><i class="fa fa-facebook-f"></i></a></li>
          <li><a href="https://twitter.com/typingvisa/" title="twitter"><i class="fa fa-twitter"></i></a></li>
          <li><a href="https://www.instagram.com/typingvisa/" title="instagram"><i class="fa fa-instagram"></i></a></li>
        </ul>

        <h4 style={{direction: "ltr", marginTop: "38px"}}>Typingvisa is part of ALNOKHATHA Group.</h4>


      </div>
    </div>
  </div>
</footer>

<section class="copyright">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ">
        <div class="text-center text-white">
          &copy; 2022 typingvisa.com  Developped By <a href="https://alnokhatha.ae">www.alnokhatha.ae</a>
        </div>
      </div>
    </div>
  </div>
</section>
<button onclick="topFunction()" id="myBtn" class="dark-box" title="Go to top"><i class="fas fa-angle-up"></i></button>
    <Link onClick={() => window.open( 'https://api.whatsapp.com/send?phone=971563800082&text=Hello')} class="float" target="_blank">
      <i className="fa fa-whatsapp my-float"></i>
    </Link>
    </div>
  );
}
export default Footer;