import React, { useRef, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css';
import Divs from "./Common/Divs";
import Imgdiv from "./Common/Imgdiv";
import Services from "./Common/Services";
import Whychose from "./Common/Whychose";
import Slider from "./Slider/Slider";
import About_2side from "./About/About_2side";
import Nav from "./Navbar";
import Footer from "./Footer";

function ArabicAbout() {
  return (
    <div>
      <Nav />
<Slider />
<About_2side />

<Divs />
<Imgdiv />
<Services />
<Whychose />
<Footer />
</div>


  );
}

export default ArabicAbout;