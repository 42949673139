
import ty1 from "../../images/ty1.jpg";
import ty2 from "../../images/ty2.jpg";
import { zoomInLeft, zoomInRight } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


const styles = {
    zoomInLeft: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInLeft, 'zoomInLeft')
    },
    zoomInRight: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInRight, 'zoomInRight')
    }
  }
  

function Divs() {
  return (
    <StyleRoot>
    <div>
        <div className="container-fluid ar" >
            <div className="row mt-5" >
                <div className="col-md-6" style={styles.zoomInLeft}>
                    <figure class="snip0056" >
                        <figcaption>
                          <h2 style={{textAlign:'right'}}><span>خدمات الطباعة</span> </h2>
                          <p style={{textAlign:'right'}}>تقدم Typingvisa مجموعة من خدمات الكتابة المتخصصة والدقيقة عبر الإنترنت وغير المتصلة بالإنترنت بأسعار تنافسية للغاية. </p>
                       </figcaption>
                        <img  src={ty1} alt="sample8" />
                    </figure>
                </div>
                <div className="col-md-6" style={styles.zoomInRight}>
                    <figure class="snip0056 yellow">
                    <figcaption>
                        <h2 style={{textAlign:'right'}}><span >اعداد الاعمال و الخدمات بإحترافية</span></h2>
                        <p style={{textAlign:'right'}}>نحن الافضل في تقديم خدمات إعداد الأعمال. يكون إنشاء شركة في الشارقة أكثر راحة فقط إذا كان لديك حق الوصول إلى الجوانب القانونية الحكومية.</p>
                    </figcaption>
                    <img  src={ty2} alt="sample8" />
                  </figure>
                </div>
            </div>
        </div>
    </div>
    </StyleRoot>
  );
}
export default Divs;