import React, { useRef, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css';

import ServicesPage from "./Services/ServicesPage";
import Services1 from "./Common/Services";
import Whychose from "./Common/Whychose";
import Slider from "./Slider/Slider";
import Nav from "./Navbar";
import Footer from "./Footer";

function ArabicServices() {
  return (
    <div>
      <Nav />
      <ServicesPage />
      <Slider />
      <Services1 />
      <Whychose />
      <Footer />
    </div>
  );
}

export default ArabicServices;