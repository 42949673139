import React from 'react';




 function GMap() {
  return (
    <div>
        <div class="container-fluid" id="whychose" >
        <div class="row">
          <div class="col-md-12 mt-3">
                <div className="google-map-code" style={{margin: "0px -12px;"}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.8818713930996!2d55.626629250635524!3d25.34174468374974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f3a925bebdd5%3A0x6ebb04577f4e5f33!2sAL%20KHIDMA%20AL%20THABIA%20TYPING!5e0!3m2!1sen!2sae!4v1642598298440!5m2!1sen!2sae" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 export default GMap

