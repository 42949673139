import em1 from "../../images/em1.jpg";
import em2 from "../../images/em2.jpg";
import em3 from "../../images/em3.jpg";
import em4 from "../../images/em4.jpg";
import em5 from "../../images/em5.jpg";
import em6 from "../../images/em6.jpg";
import em7 from "../../images/em7.jpg";
import em8 from "../../images/em8.jpg";
import em9 from "../../images/em9.jpg";
import em10 from "../../images/em10.jpg";
import em11 from "../../images/em11.jpg";
import em12 from "../../images/em12.jpg";

import Aos from "aos";
import "aos/dist/aos.css";

import { zoomInLeft, zoomInRight, zoomInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


const styles = {
    zoomInLeft: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInLeft, 'zoomInLeft')
    },
    zoomInRight: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInRight, 'zoomInRight')
    },
    zoomInUp: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInUp, 'zoomInUp')
    }
  }

Aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-out-cubic",
  delay: 50
});


function Services() {
  return (
    <StyleRoot>
    <div>
      <div class="container-fluid wedo ar" id="wedo" >
        <div class="container p-5" >
          <div class="row">
            <h2  style={{fontWeight: "600"}}>خدماتنا</h2>
            <div class="col-md-4 mt-2 p-3" >
              <div style={styles.zoomInLeft}>
              <img src={em1} alt="" /> <br /><br />
              <h4 >طباعة الهوية الاماراتية</h4>
              <p> من الضروري قانونيًا لجميع مواطني دولة الإمارات العربية المتحدة والمقيمين التقدم بطلب للحصول عليها وحملها معهم في جميع الأوقات.</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div style={styles.zoomInUp}>
              <img src={em2} alt="" /> <br /><br />
              <h4>خدمات تسهيل</h4>
              <p>تسهيل هو نظام حكومي عبر الإنترنت يبسط العمليات المتعلقة بالعمل لكل من صاحب العمل والموظف.</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div style={styles.zoomInLeft}>
              <img src={em3} alt="" /> <br /><br />
              <h4>ختم التأشيرة</h4>
              <p>يحتاج جميع الأفراد الذين يعتزمون الإقامة في دولة الإمارات العربية المتحدة إلى تأشيرة مختومة على جواز السفر لتغيير وضع الهجرة إلى مقيم. </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em4} alt=""  /> <br /><br />
              <h4>الطباعة الطبية</h4>
              <p>يحتاج جميع الأفراد الذين يعتزمون الإقامة في الإمارات العربية المتحدة إلى الطباعة الطبية لمعالجة التأشيرة المختومة على جواز السفر وتغيير حالة الهجرة إلى المقيم. </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-up">
              <img src={em5} alt="" /> <br /><br />
              <h4>التأمين على السيارات والتأمين الصحي</h4>
              <p>يوفر الأمن المالي ضد الأضرار المادية أو الإصابة الجسدية من الحوادث وضد المسؤولية.  </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em6} alt="" /> <br /><br />
              <h4>خدمات قانونية</h4>
              <p>توحد الخدمات القانونية المستهلكين مع محامين من مختلف مكاتب المحاماة. نجمع معا. عملاء ومحامين في الإمارات. </p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em7} alt=""  /> <br /><br />
              <h4>خدمات الهجرة</h4>
              <p>نتأكد من تقديم أفضل وأدق النصائح القانونية لعملائنا للعيش بشكل دائم في أي بلد.</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-up">
              <img src={em8} alt="" /> <br /><br />
              <h4>خدمات دائرة التنمية الاقتصادية</h4>
              <p>نحن نقدم خدمات الترخيص والتصاريح التجارية وخدمات التفتيش وخدمات مراقبة الأعمال</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em9} alt="" /> <br /><br />
              <h4>خدمات الوزارة الداخلية </h4>
              <p>نحن نقدم جميع أنواع خدمات وزارة الداخلية لدولة الإمارات العربية المتحدة.</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
                <img src={em10} alt=""  /> <br /><br />
                <h4>زيارة خدمات التأشيرات</h4>
                <p>هل تتقدم بطلب للحصول على تأشيرة زيارة؟ نحن نقدم تأشيرات للعلاقات العامة والعمل والسياحية والتجارية والطلاب والأسرة.</p>
              </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-up">
              <img src={em11} alt="" /> <br /><br />
              <h4>خدمات التذاكر</h4>
              <p>نحن نقدم خدمات الترخيص والتصاريح التجارية وخدمات التفتيش وخدمات مراقبة الأعمال</p>
            </div>
            </div>
            <div class="col-md-4 mt-2 p-3" >
              <div data-aos="fade-right">
              <img src={em12} alt="" /> <br /><br />
              <h4>خدمات شرطة الشارقة</h4>
              <p>نحن نقدم خدمات شرطة الشارقة مثل الإبلاغ عن الحادث الأول ، الإبلاغ عن الحالة الجنائية ، إلخ</p>
            </div>
            </div>
          </div>
        </div>
      </div>
         <br />
    </div>
    </StyleRoot>
  );
}
export default Services;