import React, { useState, useEffect } from "react";
import slides from "./data";
import "./SliderArabic.css";

const Slider = () => {
  const [allSlides, setAllSlides] = useState(slides);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (index < 0) {
      setIndex(allSlides.length - 1);
    }
    if (index > allSlides.length - 1) {
      setIndex(0);
    }
  }, [index, allSlides]);

  useEffect(() => {
    let timerId = setTimeout(() => {
      setIndex(index + 1);
    }, 5000);

    return () => {
      clearTimeout(timerId);
    };
  });

  return (
    <section className="slider" >
      <div className="slider-container">
        {allSlides.map((slide, slideIndex) => {
          const { id, image, carName, content } = slide;
          let position = "nextSlide";
          if (slideIndex === index) {
            position = "activeSlide";
          }
          if (
            slideIndex === index - 1 ||
            (slideIndex === 0 && slideIndex === allSlides.length - 1)
          ) {
            position = "prevSlide";
          }
          return (
            <header key={id} className={position}>
              <img src={image} alt={slide.carName} />
              <article>
                <h2 className="title" style={{letterSpacing:'0px'}}>{carName}</h2>
                <p className="info">{content}</p>
              </article>
            </header>
          );
        })}
        <button className="sbtn prev" onClick={() => setIndex(index - 1)}>
          ⬅
        </button>
        <button className="sbtn next" onClick={() => setIndex(index + 1)}>
          ➡
        </button>
      </div>
    </section>
  );
};

export default Slider;
