import React from 'react';
import axios from 'axios';
import GMap from "./Common/Map";
import ContactForm from "./Common/ContactForm";
import Contact_address from "./Common/Contact_address";
import Slider from "./Slider/Slider";
import Nav from "./Navbar";
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';
class Contact extends React.Component {

  render() {
    return(
      <div>
        <Helmet>
          <title>Contact - typingvisa | Emirates ID & Medical Typing In Sharjah</title>
          <meta name="description" content="Contact Typing Visa – visa, Emirates ID & Medical Typing, Translation, Visa renewal OHC Typing, Insurance in Sharjah - call 563800082"/>
          <link rel="canonical" href="/contact" />
          <meta name="keywords"content="ica, sharjah police, icp, tourist visa, business visa, remote work visa, student, patient, patient companion visa, transit, retirement visa, golden visa"/>
        </Helmet>
        <Nav/>
        <ContactForm />
        <Contact_address />
        <Slider />
        <GMap />
        <Footer /> 
      </div>
    );
  }
}

export default Contact;