
import im1 from "../../images/about_img2.jpg";
import React from 'react';
import { fadeIn } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


const styles = {
    fadeIn: {
      animation: 'x 3s',
      animationName: Radium.keyframes(fadeIn, 'fadeIn')
    }
  }
  
  class About_2side extends React.Component {
    render() { return (
    <StyleRoot>
    <div >
        <div class="container m_content mb-5 ar" style={styles.fadeIn}>
        <h1>حولنا</h1>
            <div class="row">
                <div class="col-md-4">
                <div class="m_box">
                    <div class="mb_c">
                    <img src={im1} alt="" />
                    </div>
                </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-6">
                  <div class="mc_t">
                      <p className="about_text"><br /> <b> typingvisa.com</b> ، تأسست في عام 2016 بهدف بناء شركة معروفة في الإمارات العربية المتحدة بتجارب عملاء رائعة ، وخدماتها العاجلة ، يقودها فريق من الموظفين الذين يحبون ما يفعلونه والبيئة التي يعملون فيها. typingvisa هي شركة قائمة على الخدمة ، كونها تنعم بعلاقات تجارية قوية وعلاقات مهنية مع الوكالات الحكومية المحلية وأصحاب العقارات والبنوك وكيانات المنطقة الحرة والموردين الآخرين ، يمكننا مساعدتك في إعداد عملياتك وبناء العلاقات بسرعة. من خلال مكتبنا ، نقدم خدماتنا لتلبية الاحتياجات قصيرة وطويلة الأجل للعملاء من الشركات مع خدمات احترافية وسريعة تدعم الشركات الصغيرة والمتوسطة والكبيرة. </p> <br /><br /><br />
                   </div>
                </div>
            </div>
        </div>

    </div>
    </StyleRoot>
    );
    }
  }


export default About_2side;