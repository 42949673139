import React from 'react';
import sc1 from "../../../svg/sc1.svg";
import sc2 from "../../../svg/sc2.svg";
import sc3 from "../../../svg/sc3.svg";
import sc4 from "../../../svg/sc4.svg";
import sc5 from "../../../svg/sc5.svg";
import sc6 from "../../../svg/sc6.svg";
import './arServicePage.css';


import Aos from "aos";
import "aos/dist/aos.css";

import { zoomInLeft, zoomInRight, zoomInUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


const styles = {
    zoomInLeft: {
      animation: 'x 2s',
      animationName: Radium.keyframes(zoomInLeft, 'zoomInLeft')
    },
    zoomInRight: {
      animation: 'x 4s',
      animationName: Radium.keyframes(zoomInRight, 'zoomInRight')
    },
    zoomInUp: {
      animation: 'x 6s',
      animationName: Radium.keyframes(zoomInUp, 'zoomInUp')
    }
  }


Aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-out-cubic",
  delay: 50
});


 function ServicesPage() {
  return (
    <StyleRoot>
    <div className='container-fluid' style={{backgroundColor:'black'}}>
        <div className='container row pt-5 pb-5 ar_sc_sr ar'>
            <div className='col-md-6'>
                <h2>
                    <span>خدماتنا الرئيسية: </span>
                    <p className='mt-4'style={styles.zoomInLeft}>كتابة الهجرة</p> 
                    <p style={styles.zoomInRight}>خدمات الهوية الإماراتية</p> 
                    <p style={styles.zoomInUp}>خدمات البلدية</p> 
                    <p style={styles.zoomInLeft}>خدمات شرطة الشارقة</p> 
                    <p style={styles.zoomInRight}>فتح ملف المرور</p> 
                    <p style={styles.zoomInUp}>تسجيل وتجديد رخصة المركبة</p> 
                    <p style={styles.zoomInLeft}>اصدار رخصة القيادة وتجديدها</p> 
                    <p style={styles.zoomInRight}>طباعة باللغتين العربية والانجليزية</p> 
                    <p style={styles.zoomInUp}>زيارة خدمات التأشيرات</p> 
                    <p style={styles.zoomInLeft}>رعاية محلية</p> 
                    <p style={styles.zoomInRight}>تأمين السيارة</p> 
                    </h2>
            </div>
            <div className='col-md-6 d-none d-lg-block'>
                <div className='row ar_sc'>
                    <div className='col-md-6 ar_sc1'>
                            <img src={sc1} alt="" style={styles.zoomInLeft}/>
                    </div>
                    <div className='col-md-6 ar_sc2'>
                            <img src={sc2} alt="" style={styles.zoomInRight}/>
                    </div>
                    <div className='col-md-6 ar_sc3'>
                            <img src={sc3} alt="" style={styles.zoomInUp}/>
                    </div>
                    <div className='col-md-6 ar_sc4'>
                            <img src={sc4} alt="" style={styles.zoomInLeft}/>
                    </div>
                    <div className='col-md-6 ar_sc5' >
                            <img src={sc5} alt="" style={styles.zoomInRight}/>
                    </div>
                    <div className='col-md-6 ar_sc6' >
                            <img src={sc6} alt="" style={styles.zoomInUp}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </StyleRoot>
  );
}
 export default ServicesPage