import Ferrari from "./../img/sh11.jpg";
import Lamborghini from "./../img/sh2.jpg";
import Mustang from "./../img/sh3.jpg";
import Porsche from "./../img/sh4.jpg";

const sliderData = [
  {
    id: 1,
    image: Ferrari,
    carName: "خدمات طباعة المستندات",
    content:
      "يمكنك الاتصال بنا للحصول على جميع أنواع خدمات طباعة المستندات.",
  },
  {
    id: 2,
    image: Lamborghini,
    carName: "سياحة و سفر",
    content:
      "نحن نقدم خدمات السفر إلى بلدان مختلفة ، احجز رحلتك معنا",
  },
  {
    id: 3,
    image: Mustang,
    carName: "خدمات التأشيرات",
    content:
      "نحن نقدم جميع أنواع خدمات التأشيرات. قد يناسب احتياجاتك",
  },
  {
    id: 4,
    image: Porsche,
    carName: "خدمات تسهيل",
    content:
      "اتصل بنا لجميع أنواع خدمات تسهيل",
  },
];

export default sliderData;
