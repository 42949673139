import React, { useRef, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css';
import Services1 from "./Common/Services";
import ServicesPage from "./Services/ServicesPage";
import Whychose from "./Common/Whychose";
import Slider from "./Slider/Slider";
import Nav from "./Navbar";
import Footer from './Footer';
import { Helmet } from "react-helmet-async";

function Services() {
  return (
    <div>
        <Helmet>
        <title>Services - typingvisa | Best Govt Approved Typing Center</title>
        <meta name="description" content="Govt Approved Visa Typing Centers | typingvisa is the best approved visa typing centre in UAE which is conveniently located in sharjah"/>
        <link rel="canonical" href="/services" />
        <meta name="keywords"content="ica, sharjah police, icp, tourist visa, business visa, remote work visa, student, patient, patient companion visa, transit, retirement visa, golden visa"/>
      </Helmet>

      <Nav />
      <ServicesPage />
      <Slider />
      <Services1 />
      <Whychose />
      <Footer />
    </div>
  );
}

export default Services;