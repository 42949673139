import React from 'react';
import axios from 'axios';
import './Contact_arabic.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faHeadset, faAddressBook } from "@fortawesome/free-solid-svg-icons";
import ty3 from "../../images/ty3.jpg";
import ReCAPTCHA from 'react-google-recaptcha';

import Aos from "aos";
import "aos/dist/aos.css";

const API = "http://taskmanager.alnokhatha.tech/email/";


Aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-out-cubic",
  delay: 50
});

function onChange(value) {
  console.log('Captcha value:', value);
  axios({
    method: "POST",
    url: API+"captcha.php",
    data:  {'val' : value}
  }).then((response)=>{
    console.log(response);
    if (response.data.status === 'success') {
      
      // alert(response.data.message);
      // $('.mail-button').text('hhhh');
      // setDisable(false);
    } else if (response.data.status === 'fail') {
      // alert(response.data.message);
    }
  })
}

class ContactForm  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          message: ''
        }
      }
      handleSubmit(e){
        e.preventDefault();
        // alert('55');
        axios({
          method: "POST",
          url:"http://taskmanager.alnokhatha.tech/email/sendmail.php",
          data:  this.state
        }).then((response)=>{
          console.log(response);
          if (response.data.status === 'success') {
        
            alert("Thank you, Your Email Has Been Sent Successfully.");
            this.resetForm()
          } else if (response.data.status === 'fail') {
            alert("Email Sending Failed")
          }
        })
      }
    
      resetForm(){
        this.setState({name: '', email: '', message: ''})
      }

render(){
    return(
        <>

        <div className='container-fluid ar pb-5 pt-5' style={{backgroundImage: `url(${ty3})`, height:'100%',  objectFit:'cover' , backgroundRepeat:'no-repeat' , backgroundSize:'100%'}}>
         <div>
          <div className='row mt-5 mb-5'>
            <div className='col-md-6' data-aos="zoom-in-right">
              <h5></h5>
                  <form  onSubmit={this.handleSubmit.bind(this)} method="POST">
                              <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" name="name" class="form-control mb-2" id="first-name" placeholder="أدخل أسمك *" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                    <input type="email" name="email" class="form-control mb-2" id="email" placeholder="أدخل بريدك الإلكتروني *" required="required" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                      <textarea rows="4" name="message" class="form-control mb-2" id="description" placeholder="أدخل رسالتك *" value={this.state.message} onChange={this.onMessageChange.bind(this)}/>
                                </div>
                              </div>

                              <div class="col-md-12">
                                          {/* <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange}/> */}
                                          <ReCAPTCHA sitekey="6Leh340eAAAAAKVfwmplQPsbirxA5x16zC2y4FrW" onChange={onChange} />
                                    </div>

                              <div class="col-md-12">
                                  <button type="submit" class="btn mt-4 mail-button" style={{backgroundColor: '#b8860b', color:'white'}}>
                                  <FontAwesomeIcon icon={faEnvelope} size="2x" pull="left" /> <span>Mail Us</span>
                                  </button>
                              </div>
                  </form>
            </div>
            <div className='col-md-6 ar-c' data-aos="zoom-in-right">
              <h2>هل لديك اي استفسار؟</h2> <br />
              <h5>اتصل بنا لأية أنواع من الاستفسارات. نحن هنا لمساعدتك. </h5> <br />
              <h4>لا تتردد في مراسلتنا عبر البريد لأي نوع من أنواع استفساراتك. <br /> <br /> نحن هنا لمساعدتك. </h4>
            </div>
          </div>
          </div>
        </div>
        </>
    );
}

onNameChange(event) {
    this.setState({name: event.target.value})
}

onEmailChange(event) {
    this.setState({email: event.target.value})
}

onMessageChange(event) {
    this.setState({message: event.target.value})
}

}

export default ContactForm;