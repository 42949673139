import React, { useRef, useEffect } from "react";
import 'font-awesome/css/font-awesome.min.css';
import Divs from "./Common/Divs";
import Imgdiv from "./Common/Imgdiv";
import Services from "./Common/Services";
import Whychose from "./Common/Whychose";
import Slider from "./Slider/Slider";
import Nav from './Navbar';
import Footer from './Footer';
import { Helmet } from "react-helmet-async";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init({
  offset: 200,
  duration: 600,
  easing: "ease-in-sine",
  delay: 100
});

function Home() {
  return (
    <div>
      <Helmet>
        <title>typingvisa - Best Visa Typing Services in Sharjah</title>
        <meta name="description" content="Approved Visa Typing Centers | typingvisa is the best approved visa typing centre in UAE which is conveniently located in sharjah"/>
        <link rel="canonical" href="https://typingvisa.com/" />
        <meta name="keywords"content="Typing, Visit Visa, Work Permit, Business Setup, Typing in Sharjah"/>
      </Helmet>

      <Nav />
      <Slider />
      <Divs />
      <Imgdiv />
      <Services />
      <Whychose />
      <Footer />
   </div>
  );
}



export default Home;