import bgw from "../../images/choose-list-background.jpg";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init({
  offset: 200,
  duration: 1000,
  easing: "ease-in-out-cubic",
  delay: 50
});


function Whychose() {
  return (
    <div>
      <div class="container mb-5 mt-5" id="whychose" >
        <div class="row">
          <div class="col-md-6" >
            <div data-aos="fade-right"> 
              <img class="why-chose-us" src={bgw} />
            </div>
          </div>
          <div class="col-md-6 p-5" >
            <div data-aos="fade-left"> 
            <h2>Why Chose Us?</h2> <br />
            <p>
              With a service history of more than six years, we are pleased to serve you.
               <br /> <br />
              We Care About Your Valuable  Time
              <br /><br />
              We Care About Your Money
              <br /><br />
              We Care About Your Satisfaction
              <br /><br />
              We Put All Our Effort And Experience To Better Your Service
            </p>
            </div>
          </div>
        </div>
      </div>
<br /><br />
    </div>
  );
}
export default Whychose;