import React from 'react';
import axios from 'axios';
import GMap from "./Common/Map";
import ContactForm from "./Common/ContactForm";
import Contact_address from "./Common/Contact_address";
import Slider from "./Slider/Slider";
import Nav from "./Navbar";
import Footer from "./Footer";

class ArabicContact extends React.Component {

  render() {
    return(
      <div>
        <Nav />
        <ContactForm />
        <Contact_address />
        <Slider />
        <GMap /> 
        <Footer />
      </div>
    );
  }


}

export default ArabicContact;