
import im1 from "../../images/about_img2.jpg";
import React from 'react';
import { fadeIn } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


const styles = {
    fadeIn: {
      animation: 'x 3s',
      animationName: Radium.keyframes(fadeIn, 'fadeIn')
    }
  }
  
  class About_2side extends React.Component {
    render() { return (
    <StyleRoot>
    <div>
        <div class="container m_content mb-5" style={styles.fadeIn}>
        <h1>About Us</h1>
            <div class="row">
                <div class="col-md-4">
                <div class="m_box">
                    <div class="mb_c">
                    <img src={im1} alt="" />
                    </div>
                </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-6">
                <div class="mc_t">
                
                <p className="about_text"><br /> <b> typingvisa.com</b> , was founded in 2016 with intention of building a business known in the UAE for great customer experiences, its urgent services, driven by a team of employees who love what they do and the environment they work in. typingvisa is a service based company, being blessed with strong business ties and professional relationship with local government agencies, landlords, banks, free zone entities and other suppliers we can help you set up your operation and build relations rapidly. Through our office, we deliver our services catered to the short and long term needs of corporate clients with professional and swift services supporting small, medium and large companies. </p> <br /><br /><br />
                   </div>
                </div>
            </div>
        </div>

    </div>
    </StyleRoot>
    );
    }
  }


export default About_2side;